import React from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'

import styles from './style.module.sass'
import Button from '../../Shared/UI/Button'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'

const Hero = ({ additionalHeader, hero }) => (
  <Container classes={styles.outer}>
    <Wrapper classes={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.box1}>
          <Title classes={styles.heading} blueLine={false}>
            <h1 className={styles.h1} dangerouslySetInnerHTML={{ __html: hero.title }} />
          </Title>
          <Content classes={styles.description} tag="div">
            {hero.body}
          </Content>
          <Button btnType="full" classes={styles.button} url={additionalHeader.viewOpenPositionUrl}>
            View OPEN POSITIONS
          </Button>
        </div>
        <div className={styles.box2}>
          <img
            {...srcSetProps(sanityImageUrl(additionalHeader.image))}
            className={cx(styles.rightImg)}
            alt={additionalHeader.image?.caption}
          />
        </div>
      </div>
    </Wrapper>
  </Container>
)

export default Hero
