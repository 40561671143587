import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Hero from '../components/CareersOldPage/CareersHero'
import OurValues from '../components/CareersOldPage/OurValues'
import LifeAtMaximus from '../components/CareersOldPage/LifeAtMaximus'
import WorldClassBenefits from '../components/CareersOldPage/WorldClassBenefits'
import RecruitmentProcess from '../components/CareersOldPage/RecruitmentProcess'
import OpenPositions from '../components/CareersOldPage/OpenPositions'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout customHero={<Hero additionalHeader={data.additionalHeader} hero={data.hero} />} data={data}>
      <SEO {...data.seo} />
      <LifeAtMaximus lifeAtMaximus={data.lifeAtMaximus} />
      <OurValues ourValues={data.ourValues} />
      <WorldClassBenefits worldClassBenefits={data.worldClassBenefits} />
      <RecruitmentProcess recruitmentProcess={data.recruitmentProcess} />
      <OpenPositions openPositions={data.openPositions} />
    </Layout>
  )
}
