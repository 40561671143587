import React, { useState } from 'react'

import Content from '../../Shared/UI/Content'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'

import styles from './style.module.sass'

export default ({ ourValues }) => {
  const [currentValue, setCurrentValue] = useState(ourValues.items[0])
  const [mobileCurrentItem, setMobileCurrentItem] = useState(null)

  const selectSection = item => {
    setCurrentValue(item)

    if (item == mobileCurrentItem) {
      setMobileCurrentItem(null)
    } else {
      setMobileCurrentItem(item)
    }
  }

  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <div className={styles.halfBlue} />
        <Item classes={styles.contentContainer}>
          <Title classes={styles.title}>{ourValues.title}</Title>
          <img
            className={styles.image}
            {...srcSetProps(sanityImageUrl(currentValue.image))}
            alt={currentValue.image?.caption}
          />
        </Item>
      </Wrapper>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer)}>
          <div className={styles.desktop}>
            <div className={styles.desktopNavigation}>
              {ourValues.items &&
                ourValues.items.map(item => {
                  return (
                    <div
                      key={item._key}
                      className={cx(styles.item, {
                        [styles.active]: currentValue === item,
                      })}
                      onClick={() => setCurrentValue(item)}
                    >
                      {item.title}
                    </div>
                  )
                })}
            </div>
            <div className={styles.desktopContent}>
              <div className={styles.heading}>
                {currentValue.title}&nbsp;
                <span className={styles.headingBlue}>{currentValue.blueTitle}</span>
              </div>
              <div className={styles.description}>{prepareParagraph(currentValue.description)}</div>
            </div>
          </div>
          <div className={cx(styles.mobile, 'show-mobile')}>
            {ourValues.items &&
              ourValues.items.map(item => (
                <React.Fragment key={item._key}>
                  <div
                    className={cx(styles.details, {
                      [styles.opened]: item == mobileCurrentItem,
                    })}
                  >
                    <div className={cx(styles.heading, styles.summary)} onClick={() => selectSection(item)}>
                      {item.title}:&nbsp;
                      <span className={styles.headingBlue}>{item.blueTitle}</span>
                    </div>
                    <Content classes={styles.item}>{prepareParagraph(item.description)}</Content>
                  </div>
                  <div className={styles.delimiter} />
                </React.Fragment>
              ))}
          </div>
        </Item>
      </Wrapper>
    </Container>
  )
}
