import React from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'

import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/format'

export default ({ recruitmentProcess }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title>{recruitmentProcess.title}</Title>
        </Item>
        <Item classes={cx(styles.contentContainer, styles.steps)}>
          {recruitmentProcess.steps &&
            recruitmentProcess.steps.map((step, index) => {
              return (
                <div className={styles.stepWrapper} key={step._key}>
                  <div className={styles.step} key={step._key}>
                    <div className={styles.step__content}>
                      <div className={styles.step__content__heading}>
                        <div className={styles.step__bubble}>{index + 1}</div>
                        <span>{step.title}</span>
                      </div>
                      <div className={styles.step__content__text}>{prepareParagraph(step.description)}</div>
                    </div>
                  </div>
                  <div
                    className={cx(styles.arrow, {
                      [styles.flipped]: index % 2 === 1,
                    })}
                  >
                    {index !== recruitmentProcess.steps.length - 1 && (
                      <>
                        <span className={styles.horizontal} />
                        <span className={styles.vertical} />
                        <span className={styles.pointer} />
                      </>
                    )}
                  </div>
                </div>
              )
            })}
        </Item>
      </Wrapper>
    </Container>
  )
}
